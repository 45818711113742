<template>
  <div>
    <span data-toggle="modal" data-target="#staticBackdrop" ref="modal"></span>
    <div
      class="modal fade"
      id="staticBackdrop"
      data-backdrop="static"
      data-keyboard="false"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="repairsList">
            <table class="table table-sm table-bordered">
              <tr v-for="(field, idx) in fields" :key="idx + 'f'">
                <td>{{ field.title }}</td>
                <td v-for="(item, idx) in repairsList" :key="idx + '1'">
                  <div v-if="field.podgroup && !field.zap && !field.href">
                    {{ repairsList[idx][field.group][field.name] }}
                  </div>
                  <div
                    v-if="
                      field.href && repairsList[idx][field.group][field.name]
                    "
                  >
                    <a
                      :href="repairsList[idx][field.group][field.name]"
                      target="_blank"
                      >{{ field.sub_title }}</a
                    >
                  </div>
                  <div v-if="!field.podgroup && !field.zap && !field.foto">
                    {{ repairsList[idx][field.group] }}
                  </div>
                  <div v-if="!field.podgroup && field.zap">
                    <table class="table table-sm zap">
                      <tbody v-if="repairsList[idx].zap">
                        <tr>
                          <th colspan="5">запчасти калибра</th>
                        </tr>
                        <tr
                          v-for="(item3, idx) in repairsList[idx].zap"
                          :key="idx + '3'"
                        >
                          <td>{{ item3.cod }}</td>
                          <td>{{ item3.name }}</td>
                          <td>{{ item3.art }}</td>
                          <td>{{ item3.pcs }}</td>
                          <td>{{ item3.price }}</td>
                        </tr>
                      </tbody>
                      <tbody v-if="repairsList[idx].user_zap">
                        <tr>
                          <th colspan="5">запчасти из ранее приобретенных</th>
                        </tr>
                        <tr
                          v-for="(item3, idx) in repairsList[idx].user_zap"
                          :key="idx + '3'"
                        >
                          <td>{{ item3.cod }}</td>
                          <td>{{ item3.name }}</td>
                          <td>{{ item3.art }}</td>
                          <td>{{ item3.pcs }}</td>
                          <td>{{ item3.price }}</td>
                        </tr>
                      </tbody>
                      <tbody v-if="repairsList[idx].zap_sc">
                        <tr>
                          <th colspan="5">запчасти СЦ</th>
                        </tr>
                        <tr
                          v-for="(item3, idx) in repairsList[idx].zap_sc"
                          :key="idx + '3'"
                        >
                          <td></td>
                          <td>{{ item3.name }}</td>
                          <td></td>
                          <td>{{ item3.pcs }}</td>
                          <td>{{ item3.cena }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div v-if="field.foto">
                    <div v-if="repairsList[idx][field.group]">
                      <div
                        v-for="(item5, idx) in repairsList[idx][field.group]"
                        :key="idx + 'labelFoto'"
                      >
                        <img
                          :src="item5.path0 + item5.path"
                          alt=""
                          width="200px"
                          class="m-2"
                          @click="view_big_foto(item5.path0 + item5.path)"
                        />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="modal-footer"></div>
        </div>
      </div>
    </div>
    <div class="big-foto" v-if="visible_big_foto">
      <img
        :src="big_foto_url"
        alt=""
        class="big-foto-img"
        @click="hide_big_foto"
      />
    </div>
  </div>
</template>
<script>
import { HTTPGET } from "../api";
export default {
  name: "DuplicateViews",
  props: ["open", "repairsId"],
  computed: {
    openModal: function() {
      return this.open;
    }
  },
  watch: {
    openModal: function() {
      this.get_data();
    }
  },
  data: () => ({
    repairsList: null,
    visible_big_foto: false,
    big_foto_url: null,
    fields: [
      {
        title: "подтвержден",
        name: "repair_confirm_data",
        group: "dat",
        podgroup: true
      },
      {
        title: "заявка",
        sub_title: "перейти в заявку",
        name: "order_id",
        group: "dat",
        podgroup: true,
        href: true
      },
      { title: "СЦ", name: "user", group: "dat", podgroup: true },
      { title: "гарантия", name: "guarantee", group: "dat", podgroup: true },
      { title: "нет дефекта", name: "no_defect", group: "dat", podgroup: true },
      {
        title: "категория ремонта",
        name: "repair_category",
        group: "dat",
        podgroup: true
      },
      {
        title: "стоимость работ",
        name: "repair_price",
        group: "dat",
        podgroup: true
      },
      {
        title: "стоимость запчастей",
        name: "repair_price",
        group: "summzap",
        podgroup: false
      },
      {
        title: "номер ремонта",
        name: "repair_number",
        group: "dat",
        podgroup: true
      },
      {
        title: "",
        sub_title: "перейти в ремонт",
        name: "repair_number_href",
        group: "dat",
        podgroup: true,
        href: true
      },
      {
        title: "наименование изделия",
        name: "name_instr",
        group: "dat",
        podgroup: true
      },
      {
        title: "буквы серийного номера",
        name: "serialNumberGroup",
        group: "dat",
        podgroup: true
      },
      {
        title: "серийный номер",
        name: "serialNumber",
        group: "dat",
        podgroup: true
      },
      {
        title: "предторг",
        name: "predtorg",
        group: "dat",
        podgroup: true
      },
      {
        title: "дата продажи",
        name: "dateSale",
        group: "dat",
        podgroup: true
      },
      {
        title: "дата принятия в ремонт",
        name: "dateRepair",
        group: "dat",
        podgroup: true
      },
      {
        title: "дата создания",
        name: "date_add",
        group: "dat",
        podgroup: true
      },
      {
        title: "дата выдачи",
        name: "repair_is_over_data",
        group: "dat",
        podgroup: true
      },
      {
        title: "продавец",
        name: "seller",
        group: "dat",
        podgroup: true
      },
      {
        title: "дефект",
        name: "defect",
        group: "dat",
        podgroup: true
      },
      {
        title: "требование владельца",
        name: "typeOfRepair",
        group: "dat",
        podgroup: true
      },
      {
        title: "причина отказа от ремонта",
        name: "noRepairType",
        group: "dat",
        podgroup: true
      },
      {
        title: "проведенные работы",
        name: "workCarried",
        group: "dat",
        podgroup: true
      },
      {
        title: "номер квитанции СЦ",
        name: "receiptNumber",
        group: "dat",
        podgroup: true
      },
      {
        title: "комментарий",
        name: "comment",
        group: "dat",
        podgroup: true
      },
      {
        title: "запчасти",
        name: "zap",
        group: "zap",
        podgroup: false,
        zap: true
      },
      {
        title: "фото шильдика",
        name: "",
        group: "labelFoto",
        podgroup: false,
        foto: true
      },
      {
        title: "фото талона",
        name: "",
        group: "ticketFoto",
        podgroup: false,
        foto: true
      },
      {
        title: "фото внешнего вида",
        name: "",
        group: "viewFoto",
        podgroup: false,
        foto: true
      },
      {
        title: "фото дефекта",
        name: "",
        group: "defectDetailFoto",
        podgroup: false,
        foto: true
      },
      {
        title: "дополнительные материалы",
        name: "",
        group: "dataAdditionalFoto",
        podgroup: false,
        foto: true
      }
    ]
  }),
  methods: {
    get_data() {
      HTTPGET({
        url: "/repairs/get_duplicate_repairs.php",
        params: {
          serialNumberGroup: this.repairsId.serialNumberGroup,
          serialNumber: this.repairsId.serialNumber
        }
      })
        .then(response => (this.repairsList = response.data.list))
        .then(() => this.$refs.modal.click());
    },
    view_big_foto(url) {
      this.big_foto_url = url;
      this.visible_big_foto = true;
    },
    hide_big_foto() {
      this.big_foto_url = null;
      this.visible_big_foto = false;
    }
  }
};
</script>
<style scoped>
.modal-xl {
  margin: 10px auto;
  max-width: 95%;
}
td,
th {
  font-size: 0.7em;
  vertical-align: middle;
}
.zap {
  font-size: 1.2em;
}
th {
  text-align: center;
}
img {
  margin: auto;
  cursor: pointer;
}
.big-foto {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
  overflow: auto;
}
.big-foto-img {
  display: block;
  margin: 10px auto;
  max-width: 90%;
}
</style>
