<template>
  <div class="acts-list-wrapper col-md-12">
    <div>
      <button
        :class="`btn btn-sm btn-outline-${btnType} shadow-none b-act`"
        @click="showActs"
      >
        показать {{ buttonTitle }}
      </button>
    </div>
    <table class="table table-sm text-center">
      <thead>
        <tr>
          <th
            :colspan="USER_INFO.status === 'admin' ? 13 : 12"
            class="text-center"
            :class="title === 'подтвержденные' ? 'confirm' : 'no-confirm'"
          >
            отказы от ремонта - {{ title }}
          </th>
        </tr>
        <tr>
          <th>№</th>
          <th>№ СЦ</th>
          <th v-if="USER_INFO.status === 'admin'">СЦ</th>
          <th>создан</th>
          <th>инструмент</th>
          <th>группа</th>
          <th>S№</th>
          <th>дата продажи</th>
          <th>дата принятия в ремонт</th>
          <th>дефект</th>
          <th>продавец</th>
          <th>подтвержден</th>
          <th></th>
        </tr>
        <tr v-if="CONFIRM_OR_NOT_CONFIRM_ACTS">
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.repair_number"
              @input="getFilter('repair_number', $event)"
              size="1"
            />
          </td>
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.receiptNumber"
              @input="getFilter('receiptNumber', $event)"
              size="1"
            />
          </td>
          <td class="filter-wrapper" v-if="USER_INFO.status === 'admin'">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.sc"
              @input="getFilter('sc', $event)"
              size="1"
            />
          </td>
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.date_add"
              @input="getFilter('date_add', $event)"
              placeholder="ГГГГ-ММ-ДД"
              size="1"
            />
          </td>
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.name_instr"
              @input="getFilter('name_instr', $event)"
              size="1"
            />
          </td>
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.serialNumberGroup"
              @input="getFilter('serialNumberGroup', $event)"
              size="1"
            />
          </td>
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.serialNumber"
              @input="getFilter('serialNumber', $event)"
              size="1"
            />
          </td>
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.dateSale"
              @input="getFilter('dateSale', $event)"
              placeholder="ГГГГ-ММ-ДД"
              size="1"
            />
          </td>
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.dateRepair"
              @input="getFilter('dateRepair', $event)"
              placeholder="ГГГГ-ММ-ДД"
              size="1"
            />
          </td>
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.defect"
              @input="getFilter('defect', $event)"
              size="1"
            />
          </td>
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.seller"
              @input="getFilter('seller', $event)"
              size="1"
            />
          </td>
          <td class="filter-wrapper">
            <input
              type="text"
              class="filter form-control form-control-sm shadow-none"
              v-model="PAGINATION.repair_confirm_data"
              @input="getFilter('repair_confirm_data', $event)"
              placeholder="ГГГГ-ММ-ДД"
              size="1"
            />
          </td>
        </tr>
      </thead>
      <tbody v-if="acts_list && acts_list.length">
        <tr
          class="tr"
          v-for="(item, idx) in acts_list"
          :key="idx"
          :class="item.no_read_messages > 0 ? 'message' : ''"
        >
          <td class="nowrap">
            {{ item.repair_number }}

            <div
              v-if="
                item.duplicate_serial_number && USER_INFO.status === 'admin'
              "
            >
              <a
                :href="
                  `https://sc-kalibr.ru/repairs/repair/${item.duplicate_serial_number}`
                "
                target="_blank"
                @click.prevent="view_duplicate(item)"
                >дубликат {{ item.duplicate_serial_number_count }}шт.</a
              >
            </div>
          </td>
          <td @click="goRepair(item.remont_id)">{{ item.receiptNumber }}</td>
          <td
            @click="goRepair(item.remont_id)"
            v-if="USER_INFO.status === 'admin'"
          >
            {{ item.user }}
          </td>
          <td @click="goRepair(item.remont_id)">{{ item.date_add }}</td>
          <td @click="goRepair(item.remont_id)">{{ item.name_instr }}</td>
          <td @click="goRepair(item.remont_id)">
            {{ item.serialNumberGroup }}
          </td>
          <td @click="goRepair(item.remont_id)">{{ item.serialNumber }}</td>
          <td @click="goRepair(item.remont_id)">{{ item.dateSale }}</td>
          <td @click="goRepair(item.remont_id)">{{ item.dateRepair }}</td>
          <td @click="goRepair(item.remont_id)">{{ item.defect }}</td>
          <td @click="goRepair(item.remont_id)">{{ item.seller }}</td>
          <td @click="goRepair(item.remont_id)">
            {{ item.repair_confirm_data }}
          </td>
          <td @click="goRepair(item.remont_id)">
            <i class="bi bi-envelope-fill" v-if="item.no_read_messages > 0"
              ><a href="#" class="badge badge-danger"></a
            ></i>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      class="pagination-wrapper"
      v-if="PAGINATION.count_rows && CONFIRM_OR_NOT_CONFIRM_ACTS && acts_list"
    >
      <nav aria-label="Page navigation example" class="pagination-nav">
        <ul class="pagination pagination-sm">
          <li
            class="page-item cursor"
            @click="goPage('-')"
            :class="PAGINATION.page > 1 ? 'active' : 'disabled'"
          >
            <a class="page-link shadow-none"
              ><i class="bi bi-arrow-left"></i
            ></a>
          </li>
          <li class="page-item">
            <a class="page-link shadow-none">{{ PAGINATION.page }}</a>
          </li>
          <li class="page-item" v-if="PAGINATION.pages > 1">
            <a class="page-link shadow-none">из</a>
          </li>
          <li class="page-item" v-if="PAGINATION.pages > 1">
            <a class="page-link shadow-none">{{ PAGINATION.pages }}</a>
          </li>
          <li
            class="page-item cursor"
            @click="goPage('+')"
            :class="PAGINATION.page < PAGINATION.pages ? 'active' : 'disabled'"
          >
            <a class="page-link shadow-none"
              ><i class="bi bi-arrow-right"></i
            ></a>
          </li>
          <li class="page-item">
            <a class="page-link shadow-none">показывать по</a>
          </li>
          <li class="page-item cursor">
            <select
              id="select"
              class="page-link shadow-none"
              v-model="PAGINATION.end"
              @change="ggg"
            >
              <option value="3">3</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="500">500</option>
              <option value="1000">1000</option>
            </select>
          </li>
          <li class="page-item">
            <a class="page-link shadow-none"
              >показано {{ acts_list.length }} из
              {{ PAGINATION.count_rows }} записей</a
            >
          </li>
        </ul>
      </nav>
    </div>
    <DuplicateViews
      v-if="USER_INFO.status === 'admin'"
      :open="openDialog"
      :repairsId="duplicateId"
    />
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { HTTPGET, HTTPGETNOLOADER } from "@/api";
import DuplicateViews from "@/components/DuplicateViews.vue";
export default {
  name: "ActsList",
  components: { DuplicateViews },
  data: () => ({
    acts_list: null,
    interval: null,
    showStr: 20,
    filter: {},
    openDialog: false,
    duplicateId: null
  }),
  watch: {
    CONFIRM_OR_NOT_CONFIRM_ACTS: function() {
      if (!this.CONFIRM_OR_NOT_CONFIRM_ACTS) {
        this.setStart(null);
        this.setEnd(null);
      } else {
        this.setStart(0);
        this.setEnd(this.showStr);
      }
    }
  },
  computed: {
    ...mapGetters([
      "USER_INFO",
      "PATH",
      "CONFIRM_OR_NOT_CONFIRM_ACTS",
      "PAGINATION"
    ]),
    buttonTitle() {
      if (this.CONFIRM_OR_NOT_CONFIRM_ACTS) {
        return "не подтвержденные";
      } else {
        return "подтвержденные";
      }
    },
    title() {
      if (this.CONFIRM_OR_NOT_CONFIRM_ACTS) {
        return "подтвержденные";
      } else {
        return "не подтвержденные";
      }
    },
    btnType() {
      if (this.CONFIRM_OR_NOT_CONFIRM_ACTS) {
        return "danger";
      } else {
        return "success";
      }
    }
  },
  mounted() {
    this.ADDPATH(`отказы от ремонта / ${this.title}`);
    this.get_acts(HTTPGET);
    this.update_act_list();
    //this.setVisibleFilter();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions(["ADDPATH"]),
    ...mapMutations([
      "confirmOrNotConfirmActs",
      "setPagination",
      "setStart",
      "setEnd",
      "setShowStr",
      "setPage",
      "setPages",
      "setCountRows",
      "setFilter",
      "delFilter"
    ]),
    goRepair(id) {
      this.$router.push(`/repairs/repair/${id}?path=отказы от ремонта`);
    },
    showActs() {
      this.confirmOrNotConfirmActs();
      this.PAGINATION.page = 1;
      if (!this.CONFIRM_OR_NOT_CONFIRM_ACTS) {
        this.delFilter();
        this.setStart(null);
        this.setEnd(null);
      } else {
        this.setStart(0);
        this.setEnd(this.showStr);
      }
      this.get_acts(HTTPGET, true);
    },
    update_act_list() {
      this.interval = setInterval(() => {
        this.get_acts(HTTPGETNOLOADER);
      }, 5000);
    },
    get_acts(httpget_or_httpgetnoloader) {
      httpget_or_httpgetnoloader({
        url: "acts/acts_list.php",
        params: {
          confirm: this.CONFIRM_OR_NOT_CONFIRM_ACTS,
          start: this.PAGINATION.start,
          end: this.PAGINATION.end,
          filter: this.PAGINATION.filter
        }
      })
        .then(resp => {
          this.acts_list = resp.data.acts;
          this.setCountRows(resp.data.pagination.count_rows | null);
          this.setPages(resp.data.pagination.pages | null);
        })
        .then(() => this.ADDPATH(`отказы от ремонта / ${this.title}`));
    },
    goPage(p) {
      if (p === "+" && this.PAGINATION.page < this.PAGINATION.pages) {
        this.PAGINATION.page += 1;
        this.PAGINATION.start =
          this.PAGINATION.page * this.PAGINATION.end - this.PAGINATION.end;
        this.get_acts(HTTPGET);
      }
      if (p === "-" && this.PAGINATION.page > 1) {
        this.PAGINATION.page -= 1;
        this.PAGINATION.start =
          this.PAGINATION.page * this.PAGINATION.end - this.PAGINATION.end;
        this.get_acts(HTTPGET);
      }
    },
    ggg() {
      this.PAGINATION.page = 1;
      this.PAGINATION.start = 0;
      this.get_acts(HTTPGET);
    },
    getFilter(name, $event) {
      this.setFilter({ name, value: $event.target.value });
      this.get_acts(HTTPGET);
    },
    view_duplicate(id) {
      this.duplicateId = id;
      this.openDialog = !this.openDialog;
    }
  }
};
</script>
<style lang="scss" scoped>
table {
  font-size: 0.7em;
}
th {
  text-align: center;
  vertical-align: middle;
}
td {
  vertical-align: middle;
}
.tr:hover {
  cursor: pointer;
  background: rgb(247, 247, 247);
}
.acts-list-wrapper {
  overflow: auto;
  padding-bottom: 60px;
}
.confirm {
  color: green;
}
.no-confirm {
  color: red;
}
.b-act {
  margin-top: 10px;
  margin-bottom: 10px;
}
.bi-envelope-fill {
  font-size: 2em;
  color: red;
}
.message {
  color: red;
}
.nowrap {
  white-space: nowrap;
}
.pagination-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  padding-left: 10px;
}
.cursor {
  cursor: pointer;
}
.bi-funnel {
  float: left;
  cursor: pointer;
  color: #212529;
  margin-left: 10px;
}
.filter {
  font-size: 0.9em;
}
.disabled {
  cursor: default;
}
.page-link {
  height: 100%;
}
</style>
